<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import involvedHeroImage from "../../../../public/img/resources/involved-hero.png";
import { SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail } from "@suaw/suaw-component-library";
export default {
  name: "ResourcesGetInvolved",
  components: { ResourceFrame, SuawInputGroup, SuawHeading, SuawParagraph, SuawThumbnail },
  data() {
    return {
      involvedHeroImage
    };
  }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-get-involved-heading">Get <b>Involved</b></h1>
      <SuawHeading
        class="suaw-get-involved-intro"
        level="3"
        content="At Shut Up & Write!, we believe in the power of community and the transformative impact of writing. Here's how you can get involved:"
      />
      <SuawThumbnail class="suaw-get-involved-thumbnail" :content="involvedHeroImage" />
      <h2 class="suaw-get-involved-subtitle">
        How You Can Help
      </h2>
      <SuawParagraph
        text="<b>Show Up:</b> The simplest and most impactful way to get involved is to sign up for one of our free writing groups. Whether you prefer in-person groups or online sessions, joining a Shut Up & Write! event helps create a productive atmosphere where everyone can focus. Your presence not only supports your own writing journey but also motivates others to stay committed to theirs."
      />
      <SuawParagraph
        text="<b>Organize:</b> If you've benefited from our sessions and want to give back, consider applying to become an organizer. Hosting your own Shut Up & Write! group can be a rewarding experience, whether it's in a coffee shop, a public library, or a convenient online platform like Zoom."
      />
      <SuawParagraph
        text="We provide the tools and support you need to make your events successful. Whether you're hosting in-person or online, you'll play a crucial role in helping others turn their writing dreams into reality."
      />
      <SuawParagraph
        text="<b>Donate:</b> Your financial support is vital to our mission. By making a donation, you help us provide resources, support, and a global platform for writers of all backgrounds. Donations fund:"
      />
      <div class="suaw-get-involved-guide">
        <ul class="suaw-get-involved-guide__list">
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph text="<b>Operational Costs:</b> Ensuring our events remain free and accessible to everyone." />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph text="<b>Resources:</b> Providing materials and tools to enhance the writing experience." />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph text="<b>Outreach:</b> Expanding our reach to new communities and underrepresented writers." />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph text="<b>Development:</b> Creating new programs and initiatives to support writers at all stages of their journey." />
          </li>
        </ul>
      </div>
      <SuawParagraph text="Every contribution, big or small, helps us empower writers, build communities, and change the world through the written word." />
      <h2 class="suaw-get-involved-subtitle">
        Why Get Involved?
      </h2>
      <div class="suaw-get-involved-guide">
        <ul class="suaw-get-involved-guide__list">
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph
              text="<b>Make an Impact:</b> Your participation helps writers overcome challenges, develop a consistent writing routine, and achieve their goals. By supporting Shut Up & Write!, you contribute to a global movement that encourages creativity and productivity."
            />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph
              text="<b>Personal Growth:</b> Whether you're attending sessions or organizing them, you'll develop valuable skills such as leadership, communication, and time management. (And of course, you'll get your writing done!)"
            />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph
              text="<b>Build Connections:</b> Being part of Shut Up & Write! means joining a community of like-minded individuals who share your passion for writing. You'll build lasting relationships, find inspiration, and enjoy the camaraderie of fellow writers."
            />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph
              text="<b>Flexibility:</b> We understand that everyone's schedule and preferences are different. That's why we offer various ways to get involved, from attending and organizing events to donating. Choose the option that best fits your lifestyle and commitment level."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-get-involved-subtitle">
        Get Started Today
      </h2>
      <SuawParagraph text="Ready to make a difference? Here's how you can take the next step:" />
      <div class="suaw-get-involved-guide">
        <ul class="suaw-get-involved-guide__list">
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph text='<b>Attend an Event:</b> Find and <a href="/sign-in?tab=signup">sign up</a> for a writing session that suits you.' />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph
              text='<b>Apply to Organize:</b> Fill out our <a href="https://shutupwrite.typeform.com/to/DS7ugm?typeform-source=localhost" target="_blank" rel="noopener noreferrer">organizer application</a> and start planning your own events.'
            />
          </li>
          <li class="suaw-get-involved-guide__item">
            <SuawParagraph
              text='<b>Make a Donation:</b> Visit our <a href="https://www.writingpartners.org/donate" target="_blank" rel="noopener noreferrer">donation page</a> to contribute to our mission.'
            />
          </li>
        </ul>
      </div>
      <SuawParagraph text="Join us at Shut Up & Write! and be part of a community that turns words into action and stories into reality." />
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-get-involved-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-get-involved-intro {
  font-family: Roboto;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--sem-color-neutral-dark);
}
.suaw-get-involved-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-get-involved-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
//list
.suaw-get-involved-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
